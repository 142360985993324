.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-content {
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 6px;
  background-color: #222;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  font-size: 16px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 400ms, visibility 400ms;
}

.tooltip-content.show {
  opacity: 1.0;
  visibility: visible;
}
