/* General Styles */
* {
  box-sizing: border-box;
}

:root {
  --text-color: white;
  --link-color: white;
  --link-hover-color: red;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

body {
  font-family: Montserrat, Helvetica, sans-serif;
}

a {
  text-decoration: none;
  color: var(--link-color);
}

a:visited {
  color: var(--link-color);
}

i {
  transition: transform 0.2s ease-out;
}

i:hover {
  transform: scale(1.2);
}

.consistent {
  text-decoration: underline;
  text-underline-offset: 3px;
  transition: color 0.2s ease-out;
}

.consistent:hover {
  color: rgba(251, 191, 36);
}

a[title="Github"] {
  color: white;
}

a[title="Instagram"] {
  color: #f09433;
}

a[title="Twitter"] {
  color: rgb(29, 155, 240);
}

a[title="Youtube"] {
  color: #f50057;
}

a[title="LinkedIn"] {
  color: #0a66c2;
}

a[title="LinkedIn"]>i {
  background: linear-gradient(to bottom,
      transparent 0%,
      transparent 22%,
      white 22%,
      white 78%,
      transparent 78%,
      transparent 100%);
}

/* Home Page */
.wrapper {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  color: var(--text-color);
  background: #1a202c;
  display: flex;
  flex-direction: column;
}

.container {
  flex: 1;
  zoom: 80%;
  font-size: 62.5%;
  width: 90%;
  height: auto;
  padding-top: 30px;
  margin: 0 auto;
  text-align: center;
}

@media screen and (max-width: 480px) {
  .container {
    zoom: 85%;
  }
}

.avatar {
  background-image: url(./headshot.jpg);
  background-size: 100%;
  border-radius: 125px;
  border-style: solid;
  margin-left: auto;
  margin-right: auto;
}

/* Phone */
@media screen and (max-width: 480px) {
  .avatar {
    width: 150px;
    height: 150px;
  }
}

/* Tablet / Desktop */
@media screen and (min-width: 481px) {
  .avatar {
    width: 250px;
    height: 250px;
  }
}

.title {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1.1;
  color: inherit;
  margin-top: 30px;
}

.subtitle {
  font-size: 1.25rem;
  margin-top: 30px;
  margin-bottom: 30px;
}

.principles li {
  list-style-type: none;
  margin-top: 10px;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: bold;
}

.about {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.1;
  color: inherit;
  margin-top: 30px;
  margin-bottom: 30px;
}

.icon {
  margin: 10px !important;
  font-size: 2rem;
  cursor: pointer;
}

/* Tool-tip persists when going backwards on mobile devices, so let's hide it */
@media (max-width: 800px) {
  .__react_component_tooltip.show {
    display: none;
  }
}

/* Essay */
.tree-essay {
  width: 50%;
  margin: 0 auto;
  line-height: 2;
  font-size: 1.2rem;
  margin-bottom: 30px;
}

@media (max-width: 800px) {
  .tree-essay {
    width: 80%;
    line-height: 1.8;
  }
}

.tree-essay .link {
  text-decoration: none;
  color: black;
  text-decoration: underline;
  margin: 0 4px;
  cursor: pointer;
}

.tree-essay .links {
  margin-left: -4px;
  margin-right: 4px;
}
